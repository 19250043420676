import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

/*
Images
*/
import img1 from '../assets/images/Juan.png';
import img2 from '../assets/images/Carlos.png';


export default function AppAbout() {
    return (
        <section id="about" className="block about-block">
            <Container fluid>
                <Row>
                    <Col md={8}>
                        <div className="title-holder">
                            <h2>Quienes Somos</h2>
                            <div className="subtitle">- Aprende sobre nosotros -</div>
                        </div>
                        <div className="about-text">
                            <p>Somos una empresa de reclutamiento marítimo, con un equipo dedicado y apasionado por conectar a los mejores talentos con las oportunidades más relevantes en la industria marítima. Contamos con una amplia experiencia y conocimiento del sector marítimo, lo que nos permite entender las necesidades específicas de nuestros clientes y los perfiles de los candidatos.</p>
                            <p>Nos especializamos en la búsqueda y selección de profesionales en diversas áreas marítimas, hasta personal técnico y de gestión. Nuestro enfoque se basa en la transparencia, la ética y el compromiso con la calidad, asegurando que tanto las empresas como los candidatos encuentren la mejor opción para sus necesidades.</p>
                            <p>Además, valoramos la formación continua y el desarrollo profesional, por lo que también ofrecemos recursos y apoyo a los candidatos para que puedan crecer en sus carreras. En resumen, somos una puerta de entrada a las oportunidades en el mundo marítimo, trabajando para fortalecer la industria y contribuir a su éxito. ¿Te gustaría saber más sobre nuestros servicios o cómo podemos ayudarte?</p>
                        </div>
                    </Col>

                    <Col md={4} className="profile-section">
                        <div className="profile-image profile-image-right">
                            <Image src={img1} roundedCircle />
                            <p>Juan Cubillos</p>
                        </div>
                        <div className="profile-image profile-image-left">
                            <Image src={img2} roundedCircle />
                            <p>Carlos Tejada</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}