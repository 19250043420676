import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

export default function AppFooter() {
  const handleWhatsAppClick = () => {
    window.open("https://wa.me/56937683639", "_blank"); // Redirige a WhatsApp
  };

  const handleInstagramClick = () => {
    window.open(
      "https://www.instagram.com/tcreclutamiento?igsh=MXUwbWN4Z2U2M2V0bQ==",
      "_blank"
    ); // Redirige a Instagram
  };

  return (
    <footer
      id="footer-section"
      style={{
        backgroundImage: `url(${require('../assets/images/background.jpg')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        fontFamily: 'Arial, sans-serif',
        padding: '40px 0',
      }}
    >
      <Container fluid style={{ position: 'relative', zIndex: 1 }}>
        <Row className="align-items-center">
          <Col md={4} style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                backgroundColor: '#5fbcd3',
                color: '#ffffff',
                borderRadius: '50%',
                width: '180px',
                height: '180px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                fontSize: '1rem',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              }}
            >
              <div>
                <p style={{ margin: 0 }}>¿Dudas? ¡Contáctanos!</p>
                <a
                  href="https://wa.me/56937683639"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    textDecoration: 'none',
                  }}
                >
                  (+569) 37683639
                </a>
              </div>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '-20px',
                left: '-40px',
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: '#82cddd',
                opacity: 0.5,
              }}
            ></div>
            <div
              style={{
                position: 'absolute',
                top: '50px',
                left: '-80px',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: '#82cddd',
                opacity: 0.3,
              }}
            ></div>
          </Col>

          <Col md={4} style={{ textAlign: 'center' }}>
            <div style={{
              fontSize: '4rem',
              color: 'rgba(255, 255, 255, 0.2)',
              display: 'inline-block',
            }}>
              &#9875;
            </div>
          </Col>

          <Col md={4} style={{ textAlign: 'center' }}>
            <p style={{ margin: 0 }}>¡Síguenos en nuestras redes!</p>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'center',
                gap: '15px',
              }}
            >
              <FaWhatsapp
                onClick={handleWhatsAppClick}
                style={{ color: 'white', fontSize: '1.5rem', cursor: 'pointer', transition: 'color 0.3s' }}
                className="social-icon"
              />
              <FaInstagram
                onClick={handleInstagramClick}
                style={{ color: 'white', fontSize: '1.5rem', cursor: 'pointer', transition: 'color 0.3s' }}
                className="social-icon"
              />
            </div>
            <p style={{
              marginTop: '10px',
              fontSize: '0.8rem',
              color: '#dcdcdc',
            }}>
              Copyright © 2024 aa. Todos los derechos reservados.
            </p>
          </Col>
        </Row>
      </Container>

      <style jsx="true">{`
        .social-icon:hover {
          color: #cccccc;
        }
      `}</style>
    </footer>
  );
}
