import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import backgroundImage from '../assets/images/background.jpg'; // Ajusta la ruta según corresponda
import image1 from '../assets/images/cargos/patronnavemenor.jpg';
import image2 from '../assets/images/cargos/tripulante.jpg';
import image3 from '../assets/images/cargos/motorista.jpg';
import image4 from '../assets/images/cargos/vehiculoremoto.jpg';
import image5 from '../assets/images/cargos/guardia.jpg';
import image6 from '../assets/images/cargos/prevencionista.jpg';
import image7 from '../assets/images/cargos/cocinero.jpg';
import image8 from '../assets/images/cargos/tecnicologistico.jpg';
import image9 from '../assets/images/cargos/mas.jpg';

const jobs = [
  { 
    title: 'Patrón de nave menor', 
    image: image1, // Ruta a la imagen
  },
  { 
    title: 'Tripulante de nave menor', 
    image: image2,
  },
  { 
    title: 'Tripulante de máquinas', 
    image:  image3,
  },
  { 
    title: 'Manipulador de alimentos (cocinero)', 
    image: image7,
  },
  { 
    title: 'Operador de vehículos remotos ROV', 
    image: image4,
  },
  { 
    title: 'Prevencionista de riesgos', 
    image: image6,
  },
  { 
    title: 'Personal técnico y logístico', 
    image: image8,
  },
  { 
    title: 'Guardia Marítimo', 
    image: image5,
  },
  { 
    title: 'Y Más...', 
    image: image9,
  },
];

export default function AppCB() {
  const [hoveredJob, setHoveredJob] = useState(null);

  return (
    <section id="cargors-busqueda-section">
      {/* Background image */}
      <img src={backgroundImage} alt="Background" className="background-image" />

      <Container className="content-container">
        <h2 id="cargors-busqueda-title" className="text-center">Cargos en Búsqueda</h2>
        <Row>
          {jobs.map((job, index) => (
            <Col md={4} key={index} className="job-col">
              <Card 
                className="job-card" 
                onMouseEnter={() => setHoveredJob(index)}
                onMouseLeave={() => setHoveredJob(null)}
              >
                <div className="card-content">
                  {hoveredJob === index ? (
                    <img 
                      src={job.image} 
                      alt={job.title} 
                      className="job-image"
                    />
                  ) : (
                    <h3 className="job-title">{job.title}</h3>
                  )}
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
