import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

export default function AppHeader() {
    return (
        <header id="app-header">
            <Navbar expand="lg" className="custom-navbar nav-blue">
                <Container className="justify-content-center">
                    <Navbar.Brand href="#home" id="navbar-brand">
                        <span id="company-name">T&C Reclutamiento Marítimo</span>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </header>
    );
}
