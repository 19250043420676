import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as VisionIcon } from '../assets/icons/tsunami.svg'; 
import { ReactComponent as MissionIcon } from '../assets/icons/trophy.svg'; 

export default function appMV() {
  return (
    <section className="vision-mission-section">
      <Container>
        <Row className="vision-section">
          <Col md={12} className="text-center icon-container">
            <VisionIcon className="vision-icon" />
            <h2 className="section-title">Visión</h2>
            <p className="section-text">
              La visión de nuestra empresa de reclutamiento marítimo, se centra en convertirnos en un líder en la
              provisión de talentos altamente calificados para la industria marítima. Esto implica no solo conectar a
              los mejores profesionales con las oportunidades adecuadas, sino también fomentar un entorno de trabajo
              seguro y eficiente en el mar. También nuestro compromiso con la sostenibilidad y la innovación en el
              sector, contribuyendo al desarrollo de una fuerza laboral que esté preparada para enfrentar los desafíos
              del futuro en la navegación y la logística marítima.
            </p>
          </Col>
        </Row>

        <Row className="mission-section">
          <Col md={12} className="text-center icon-container">
            <MissionIcon className="mission-icon" />
            <h2 className="section-title">Misión</h2>
            <p className="section-text">
              La misión de nuestra empresa de reclutamiento marítimo se enfoca en proporcionar soluciones efectivas
              para la selección y colocación de profesionales en la industria marítima. Esto incluye identificar,
              evaluar y conectar a candidatos altamente calificados con las necesidades específicas de las empresas del
              sector. Además, el compromiso de garantizar un proceso de reclutamiento transparente y ético, así como
              ofrecer un servicio excepcional tanto a los empleadores como a los candidatos. En resumen, nuestro
              objetivo es contribuir al crecimiento y éxito de la industria marítima mediante la creación de equipos
              competentes y bien preparados.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
