import Carousel from 'react-bootstrap/Carousel';

const heroData = [
    {
        id: 1,
        image: require('../assets/images/image.png'),
        title: 'Expertos en Reclutamiento Maritimo',
        description: 'Te ayudamos a encontrar profesionales',
    },
];

export default function AppHero() {
    return (
        <section id="home-carousel-section">
            <Carousel id="home-carousel" data-bs-theme="white">
                {heroData.map((hero) => (
                    <Carousel.Item key={hero.id}>
                        <img
                            className="carousel-image"
                            src={hero.image}
                            alt={"Slide " + hero.id}
                        />
                        <Carousel.Caption className="carousel-caption">
                            <h1 id="carousel-title">{hero.title}</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </section>
    );
}
