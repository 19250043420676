import React, { useRef, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import emailjs from 'emailjs-com';

export default function AppContactanos() {
    const formRef = useRef();
    const [loading, setLoading] = useState(false); // Estado para el círculo de carga
    const [showModal, setShowModal] = useState(false); // Estado para el modal de confirmación
    const [modalContent, setModalContent] = useState({ title: '', message: '', success: true }); // Contenido del modal

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true); // Activa el spinner

        emailjs.sendForm(
            'service_ao4qfl8', // Reemplaza con tu Service ID de EmailJS
            'template_kjki02i', // Reemplaza con tu Template ID de EmailJS
            formRef.current,
            'ctlvD3G14cT82ixhP' // Reemplaza con tu Public Key de EmailJS
        )
        .then(
            (result) => {
                setLoading(false); // Desactiva el spinner
                setModalContent({
                    title: 'Correo enviado',
                    message: 'Tu mensaje fue enviado exitosamente. ¡Gracias por contactarnos!',
                    success: true
                });
                setShowModal(true); // Muestra el modal
                console.log(result.text);
            },
            (error) => {
                setLoading(false); // Desactiva el spinner
                setModalContent({
                    title: 'Error al enviar',
                    message: 'Hubo un problema al enviar tu mensaje. Por favor, inténtalo de nuevo más tarde.',
                    success: false
                });
                setShowModal(true); // Muestra el modal
                console.log(error.text);
            }
        );
        e.target.reset(); // Resetea el formulario después de enviarlo
    };

    return (
        <section id="contactanos-section">
            <Container className="contactanos-container">
                <Row className="justify-content-center">
                    <Col md={6} className="contactanos-col">
                        <Form ref={formRef} onSubmit={sendEmail}>
                            <h3 className="contactanos-title">Comunícate con nosotros</h3>

                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>Nombre completo</Form.Label>
                                <Form.Control type="text" name="name" placeholder="Nombre completo" required />
                            </Form.Group>

                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Correo</Form.Label>
                                <Form.Control type="email" name="email" placeholder="ejemplo@gmail.com" required />
                            </Form.Group>

                            <Form.Group controlId="formPhone" className="mb-3">
                                <Form.Label>Teléfono</Form.Label>
                                <Form.Control type="tel" name="phone" placeholder="+56" required />
                            </Form.Group>

                            <Form.Group controlId="formSelectOption" className="mb-3">
                                <Form.Label>Selecciona una opción</Form.Label>
                                <Form.Control as="select" name="option">
                                    <option>Busco contratar personal</option>
                                    <option>Busco trabajo</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formJobSelect" className="mb-3">
                                <Form.Label>Cargo</Form.Label>
                                <Form.Control as="select" name="job">
                                    <option>Patrón de nave menor</option>
                                    <option>Tripulante de nave menor</option>
                                    <option>Tripulante de máquinas</option>
                                    <option>Manipulador de alimentos (cocinero)</option>
                                    <option>Operador de vehículos remotos ROV</option>
                                    <option>Prevencionista de riesgos</option>
                                    <option>Personal técnico y logístico</option>
                                    <option>Guardia Maritimo</option>
                                </Form.Control>
                            </Form.Group>

                            {/* Botón de envío con spinner */}
                            <Button
                                type="submit"
                                className="w-100 d-flex align-items-center justify-content-center"
                                style={{ backgroundColor: '#007bff', borderColor: '#007bff', color: 'white' }}
                                disabled={loading} // Deshabilita el botón mientras está cargando
                            >
                                {loading ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    "Enviar"
                                )}
                            </Button>
                        </Form>
                    </Col>
                </Row>

                {/* Modal de confirmación */}
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalContent.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{modalContent.message}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={modalContent.success ? "success" : "danger"}
                            onClick={() => setShowModal(false)}
                        >
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </section>
    );
}
