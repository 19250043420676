import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AppHeader from './components/header';
import AppHero from './components/hero';
import AppAbout from './components/about';
import AppMV from './components/visionMision';
import AppCB from './components/cargosBusqueda';
import AppContactanos from './components/contactanos';
import AppFooter from './components/footer';

function App() {
  return (
    <div className="App">
      <header id="header">
        <AppHeader/>
      </header>
      <main>
        <AppHero/>
        <AppAbout/>
        <AppMV/>
        <AppCB/>
        <AppContactanos/>
        <AppFooter/>
      </main>
    </div>
  );
}

export default App;
